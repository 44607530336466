import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SliderElement from './SliderElement'
import logoCarbonera from '../images/carboneraslogs.png'
import logoUhMay from '../images/logoUhMay.png'
import logoAlmaCholul from '../images/logoAlmaCholul.png'
import logoCarboneras from '../images/logoCarbonerasReserva.png'



const SliderT = () => {

  const fondo = useStaticQuery(graphql`
    {
      sliderGlamping: file(relativePath: { eq: "sliderGlamping.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920) { ...GatsbyImageSharpFluid } } }
      sliderUhMay: file(relativePath: { eq: "sliderUhMay.jpg" }) {
          sharp: childImageSharp { fluid(maxWidth: 1920) { ...GatsbyImageSharpFluid } } }      
      sliderAlmaCholul: file(relativePath: { eq: "sliderAlmaCholul.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920) { ...GatsbyImageSharpFluid } } }    
      sliderCarboneras: file(relativePath: { eq: "sliderCarboneras.png" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920) { ...GatsbyImageSharpFluid } } }   
           
    }
  `)
  const desarrollos = [
    {
      nombre: "Santuario Uh May",
      fondo: fondo.sliderUhMay.sharp.fluid,
      logo: logoUhMay,
      link: "https://santuariouhmay.mx/",
      color:"rgba(181, 148, 62, var(--bg-opacity))"
    },
    {
      nombre: 'Alma Cholul',
      fondo: fondo.sliderAlmaCholul.sharp.fluid,
      logo: logoAlmaCholul,
      link: "#",
      color:"rgba(255,154,165, var(--bg-opacity))"
    },
    {
      nombre: 'Carboneras Glamping',
      fondo: fondo.sliderGlamping.sharp.fluid,
      logo: logoCarboneras,
      link: "/desarrolloCarbonerasGlamping",
      color:"rgba(140,171,103, var(--bg-opacity))"
    },
    {
      nombre: "Carboneras",
      fondo: fondo.sliderCarboneras.sharp.fluid,
      logo: logoCarbonera,
      link: "/desarrolloCarboneras",
      color:"rgba(120, 47, 66, var(--bg-opacity))"
    },
    


  ]
  // const resizeSurrounding = () => {
  //     const width = document.querySelector('#width').value;
  //     document.querySelector('#surrounding').style.width = `${width}px`;
  // };
  return (
    <div className="bg-white flex">

      {desarrollos.map(desarrollo =>
        <SliderElement
          key={desarrollo.nombre}
          fondo={desarrollo.fondo}
          logo={desarrollo.logo}
          nombre={desarrollo.nombre}
          enlace={desarrollo.link}
          color={desarrollo.color} />

      )}

    </div>
  )
}

export default SliderT

import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SliderElement from './SliderElement'
import logoXexen from '../images/logoXexenXakah.png'

import logoStoras from '../images/logoStoras.png'
import logoAlamedas from '../images/alamedasLogo.png'
import logoXomak from '../images/xomaklogo.png'


const SliderD = () => {

  const fondo = useStaticQuery(graphql`
    {
      sliderXexenXakah: file(relativePath: { eq: "sliderXexenXakah.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920) { ...GatsbyImageSharpFluid } } }
      sliderXomak: file(relativePath: { eq: "sliderXomak.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920) { ...GatsbyImageSharpFluid } } }
      sliderAlamedas: file(relativePath: { eq: "sliderAlameda.jpg" }) {
          sharp: childImageSharp { fluid(maxWidth: 1920) { ...GatsbyImageSharpFluid } } }      
     
      sliderStoras: file(relativePath: { eq: "sliderStoras.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920) { ...GatsbyImageSharpFluid } } }
      
    }
  `)
  const desarrollos = [
    {
      nombre: "Xomak",
      fondo: fondo.sliderXomak.sharp.fluid,
      logo: logoXomak,
      link: "https://xomaktulum.com/",
      color:"rgba(34, 108, 112, var(--bg-opacity))"
    },
    {
      nombre: 'Alamedas',
      fondo: fondo.sliderAlamedas.sharp.fluid,
      logo: logoAlamedas,
      link: "https://alamedas.grupolibera.mx/",
      color:"rgba(15,8,67, var(--bg-opacity))"
    },
    {
      nombre: "Storas",
      fondo: fondo.sliderStoras.sharp.fluid,
      logo: logoStoras,
      link: "https://storasindustrial.com/",
      color:"rgba(180, 114, 50,var(--bg-opacity))"
    },
    {
      nombre: "Xexén y Xakáh",
      fondo: fondo.sliderXexenXakah.sharp.fluid,
      logo: logoXexen,
      link: "https://xexenyxakah.com/",
      color:"rgba(158, 141, 70, var(--bg-opacity))"
    },

    


  ]
  // const resizeSurrounding = () => {
  //     const width = document.querySelector('#width').value;
  //     document.querySelector('#surrounding').style.width = `${width}px`;
  // };
  return (
    <div className="bg-white flex">

      {desarrollos.map(desarrollo =>
        <SliderElement
          key={desarrollo.nombre}
          fondo={desarrollo.fondo}
          logo={desarrollo.logo}
          nombre={desarrollo.nombre}
          enlace={desarrollo.link}
          color={desarrollo.color} />

      )}

    </div>
  )
}

export default SliderD

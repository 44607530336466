import React from "react"
import logo from "../images/liberalogs.png"
import logofb from "../images/icon-facebook.png"
import logoig from "../images/icon-instagram.png"
import logowa from "../images/icon-whatsapp.png"
import BackgroundImg from "gatsby-background-image"
import { graphql, Link, useStaticQuery } from "gatsby"
import cinta from '../images/cintaLogos.png'
import cintaM1 from '../images/cintaLogosM1.png'
import cintaM2 from '../images/cintaLogosM2.png'

export const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      fluid: file(relativePath: { eq: "fondo3.jpg" }) {
        sharp: childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    // <div>
    <BackgroundImg fluid={data.fluid.sharp.fluid} className="bg-fixed">
      
      <div className="bg-libera-600 bg-opacity-25">

        <div className="m-auto max-w-screen-lg font-100 text-montserrat text-sm text-white grid grid-cols-1 sm:grid-cols-3 text-center py-12 sm:py-5 items-center justify-center">
          <h2 className="py-8" style={{fontSize:18}}>
            <ul className="flex items-center justify-center pb-12">
              <li>
                <a href="https://facebook.com/grupoliberamx">
                  <img src={logofb} width="36px" alt="imagenredes" />
                </a>
              </li>
              <li>
                <a href="https://instagram.com/grupoliberamx">
                  <img src={logoig} width="36px" alt="imagenredes" />
                </a>
              </li>
              <li>
                <a href="https://wa.me/529993462300">
                  <img src={logowa} width="36px" alt="imagenredes" />
                </a>
              </li>
            </ul>
            <div className="space-y-2">
              <p>Grupo Libera</p>
              <p>Tel. 999 346 23 00</p>
              <p>Email: info@grupolibera.mx</p>
            </div>
          </h2>

          <h2 className="py-8">
            <img src={logo} width="200px" className="m-auto" alt="logo" />
          </h2>
          <div className="text-lg pt-8 pb-12 m-auto max-w-sm">
            <h1 className="text-white text-montserrat font-semibold pb-4 mt-4">
              Proyectos
            </h1>
            <div className="m-auto">
  <div className="text-xs grid grid-cols-4 sm:grid-cols-1 sm:space-y-2 sm:text-justify" style={{ color: "black" }}>
    <p><Link to="/desarrolloTapirus">Tapirus</Link></p>
    <p><Link to="/desarrolloOcelot">Ocelot</Link></p>
    <p><Link to="/desarrolloCarbonerasReserva">Carboneras</Link></p>
    <p><Link to="/desarrolloArenales">Arenales</Link></p>
    <p><Link to="/desarrolloTapirus">Terus</Link></p>
    <p><Link to="/desarrolloPuntaMaya">Punta Maya</Link></p>
    <p><Link to="/desarrolloSantaMar">Santa Mar</Link></p>
    <p><Link to="/contacto">Ayuda</Link></p>
    <p><a href="https://avisoprivacidad.grupolibera.mx/" target="_blank">Aviso de privacidad</a></p>
  </div>
</div>
          </div>
        </div>

        <div className="m-auto max-w-screen-lg font-100 text-montserrat text-sm text-white grid grid-cols-1 sm:grid-cols-1 text-center  items-center justify-center">
      <p><a href="https://pagos.grupolibera.mx/reservas/politica-de-privacidad/" target="blank_">Consulta las consideraciones bancarias de Netpay Aquí</a></p>
      </div>

      </div>
    </BackgroundImg>

    // </div>
  )
}

export default Footer

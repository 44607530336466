import React from 'react'
import BackgroundImg from 'gatsby-background-image'
import cinta from '../images/cintaLogos.png'
import cintaM1 from '../images/cintaLogosM1.png'
import cintaM2 from '../images/cintaLogosM2.png'
import cintaM3 from '../images/cintaLogosM3.png'
import { graphql, useStaticQuery } from 'gatsby'


const CintaLogos = () => {
    const { fondo, fondoblur } = useStaticQuery(graphql`
{ 
    fondo: file(relativePath: { eq: "fondo34.jpg" }) { sharp: childImageSharp {
        fluid(maxWidth: 1920) { ...GatsbyImageSharpFluid } } } 
    fondoblur: file(relativePath: { eq: "fondo1blur.jpg" }) { sharp: childImageSharp {
        fluid(maxWidth: 1920) { ...GatsbyImageSharpFluid } } } 
}
`)

    return (
        <BackgroundImg fluid={fondo.sharp.fluid} className="bg-fixed">
            {/* <div className="rounded-lg overflow-hidden "> */}
            <div className=" bg-black bg-opacity-50 py-12">
                <img src={cinta} alt="cinta logos" className="hidden md:visible md:block m-auto" />
                <img src={cintaM1} alt="cinta logos" className="md:hidden py-2" />
                <img src={cintaM2} alt="cinta logos" className="md:hidden py-2" />
                <img src={cintaM3} alt="cinta logos" className="md:hidden py-2" />
            </div>
            {/* </div> */}
        </BackgroundImg>
    )
}

export default CintaLogos

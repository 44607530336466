import React from 'react'

function DescubreComo() {
    return (
        
        <div
            className="py-24 grid grid-cols-1 items-center text-gray-700">
            <div className="container m-auto grid justify-center px-4">
                {/* <div className="px-6 sm:px-32 md:px-24 lg:px-4 xl:px-24 lg:ml-12 pt-12 my-12 m-auto container max-w-3xl z-20"> */}
                <h1 className="text-3xl sm:text-4xl md:text-4xl text-libera-500 mb-4
                    font-bold text-center text-quan leading-10 md:px-8 lg:px-0"
                    data-sal="slide-up"
                    data-sal-delay="0"
                    data-sal-easing="ease"
                    data-sal-duration="1200"
                >
                    ¡Descubre Cómo Podemos Ayudarte a Invertir!
                </h1>
                {/* <h2 className="mt-8 md:text-xl md:px-12 lg:text-3xl font-semibold lg:px-0 px-4 sm:px-0 text-base text-center"
                    data-sal="slide-up"
                    data-sal-delay="100"
                    data-sal-easing="ease"
                    data-sal-duration="1300">
                    Asegura tu futuro invirtiendo en las mejores zonas de Yucatán.
                </h2> */}
                <p className="text-center text-lg mt-8">
                    Asegura tu futuro invirtiendo en las mejores zonas de Yucatán.<br />
                    ¡Acércate a nosotros y te ayudaremos a conocer cuál es tu mejor inversión!
                </p>


                {/* </div> */}
            </div>
        </div>
    )
}

export default DescubreComo

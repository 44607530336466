import React from 'react'
import phone from '../images/phone-icon.png'
import whatsapp from '../images/logo-whatsapp.png'

export const ButtonIcon = props => {
    // const icon = whatsapp
    // console.log(props.icon)
    const icon = props.icon === "tel" ? phone : props.icon === "whatsapp" ? whatsapp : phone
    return (
        <a href={props.to}>
            <button className="shadow-xl transition duration-200 bg-libera-500 m-auto lg:m-0
            hover:bg-libera-600 text-white py-3 px-2 text-sulphur rounded-md px-4
            flex items-center content-center"
                href={props.to}>
                <img src={icon} alt="icon" width="20px" className="mr-3" />
                {props.text}
            </button>
        </a>
    )
}
export default ButtonIcon
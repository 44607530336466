import React from "react"
import BackgroundImg from "gatsby-background-image"
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from "gatsby"
import fondoIconos from "../images/fondo1.jpg"
import { ItemIcono } from './ItemIcono'
import logo from '../images/logo.png'

const AsesoriaLegal2 = props => {
    const { fondo, fondoblur } = useStaticQuery(graphql`
    { 
        fondo: file(relativePath: { eq: "fondo1.jpg" }) { sharp: childImageSharp {
            fluid(maxWidth: 1920) { ...GatsbyImageSharpFluid } } } 
        fondoblur: file(relativePath: { eq: "fondo1blur.jpg" }) { sharp: childImageSharp {
            fluid(maxWidth: 1920) { ...GatsbyImageSharpFluid } } } 
    }
  `)
    return (
        <>
            <BackgroundImg fluid={fondo.sharp.fluid} className="bg-fixed px-4 py-32">
                <div className="container m-auto rounded-md overflow-hidden ">
                    <BackgroundImg fluid={fondoblur.sharp.fluid} className=" rounded-md bg-fixed rounded-lg">
                        <div className="bg-black bg-opacity-50 grid grid-cols-1 md:grid-cols-2 py-12 items-center">
                            <div className="grid grid-cols-1 justify-center items-center">

                                <h1
                                    className="text-2xl font-semibold lg:text-3xl px-4 xl:mx-12 py-8 px-8 text-white text-center
                                lg:bg-opacity-0 rounded-lg text-quan"
                                    data-sal="slide-up"
                                    data-sal-duration="600"
                                    data-sal-easing="ease" >
                                    Asesoría legal para todo el proceso de compra y crédito
                                </h1>
                            </div>
                            <div className="md:border-l md:border-white">
                                <div
                                    data-sal="slide-up"
                                    data-sal-duration="800"
                                    data-sal-easing="ease"
                                    className="text-xs lg:text-sm grid grid-cols-1 md:grid-cols-1 ref2
                    md:px-8 xl:mx-24 justify-start"
                                >
                                    <ItemIcono texto="Atención Personalizada" icono="persona" />
                                    <ItemIcono texto="Certeza Jurídica" icono="balanza" />
                                    <ItemIcono texto="Trámites Transparentes" icono="libro" />
                                    <ItemIcono texto="Fuera de Zonas Protegidas" icono="hoja" />
                                </div>
                            </div>
                        </div>
                    </BackgroundImg>
                    <BackgroundImg fluid={fondoblur.sharp.fluid} className=" rounded-md bg-fixed rounded-lg">
                        <div className="bg-black bg-opacity-75 grid items-center">
                            <img src={logo} alt="logo" width="40px" className="m-auto my-5" />
                        </div>
                    </BackgroundImg>
                </div>
            </BackgroundImg>
        </>
    )
}

export default AsesoriaLegal2

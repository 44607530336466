import React, { useState, useEffect } from 'react'
import BackgroundImg from 'gatsby-background-image'
// import ButtonIcon from './ButtonIcon'
import { motion } from 'framer-motion'
import Style from 'style-it';

const SliderElement = (props) => {
    const [isHover, setHover] = useState(`invisible`)
    console.log(isHover)
    return Style.it(        `
        .bgdesarrollo:hover {
            --bg-opacity: 0.75;
            
            background-color: ${props.color};
        }
    `,
        // <a className="square grid" href={props.enlace}>
        <div className="square4 grid items-center overflow-hidden">
            <motion.div whileHover={{ scale: 1.2 }} transition={{ damping: 0, duration: 2.5 }} className="w-full h-full">
                <BackgroundImg className="w-full h-full"
                    fluid={props.fondo}>
                    {/* <img src={props.logo} alt={`logo ${props.nombre}`} className="w-3/4 md:w-1/2 m-auto" /> */}
                    <a href={props.enlace}
                        className="bgdesarrollo hover:bg-opacity-75 w-full h-full 
                grid transition duration-300"
                        onMouseEnter={() => setHover('visible block')}
                        onMouseLeave={() => setHover('invisible')}>
                        {/* <div className="hidden md:block"></div> */}
                        <motion.img
                            whileHover={{ scale: 1.05 }}
                            src={props.logo} alt={`logo ${props.nombre}`}
                            className="m-auto w-3/4 h-3/4 sm:w-1/2 sm:h-1/2 lg:w-1/3 lg:h-1/3" />
                        {/* <span
                    className={`border border-white text-white rounded ${isHover}
                    hover:bg-white hover:text-libera-500 transition duration-500 hidden md:block md:invisible
                    py-3 px-6 text-xs m-auto -mt-2`}>
                    Ver más
                    </span> */}
                    </a>
                </BackgroundImg>
            </motion.div>
        </div>
    )
}

export default SliderElement

import React, { useEffect } from "react"
import "../global.css"
import Footer from "../layout/Footer"
import HeadMetadata from "../components/HeadMetadata"
import NavBar from '../layout/NavBarSlim4'
import DescubreComo from '../home/DescubreComo'
import AsesoriaLegal2 from '../home/AsesoriaLegal2'
import InvierteConNosotros from "../home/InvierteConNosotros"
import UneteAlEquipo from "../home/UneteAlEquipo"
import Slider from '../home/Slider'
import SliderD from '../home/SliderD'
import SliderT from '../home/SliderT'
import CintaLogos from '../home/CintaLogos'
import PorQueInvertir3 from '../home/PorQueInvertir3'
import Carousel from '../components/MyCarousel'




export default () => {

  return (
    
    <div className="overflow-hidden">
     
      <HeadMetadata title="Grupo Libera - Invierte en Yucatán" />
      
      <NavBar />

      <Carousel />

      <DescubreComo />

      <Slider />
      <SliderD />
      <SliderT />

      <AsesoriaLegal2 />

      <InvierteConNosotros />

      <PorQueInvertir3 />

      <CintaLogos />

      {/* <Brokers /> */}

      <UneteAlEquipo />


      <Footer />
      
        
      
    </div>
    
  )
}

import React from 'react'
import ButtonIcon from './ButtonIcon'
import BackgroundImg from 'gatsby-background-image'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'

const UneteAlEquipo = () => {
    const { fondo, fondoblur } = useStaticQuery(graphql`
    { 
        fondo: file(relativePath: { eq: "fondo-reclutamiento.png" }) { sharp: childImageSharp {
            fluid(maxWidth: 1920) { ...GatsbyImageSharpFluid } } } 
        fondoblur: file(relativePath: { eq: "fondo1blur.jpg" }) { sharp: childImageSharp {
            fluid(maxWidth: 1920) { ...GatsbyImageSharpFluid } } } 
    }
  `)
    return (
        // <BackgroundImg fluid={fondo.sharp.fluid} alt="fondo reclutamiento" >
        <div className="grid grid-cols-1 items-center pt-24 md:pt-32 lg:pt-48 text-gray-700">
            <div className="justify-center grid grid-cols-1">
                <h1 className="text-3xl md:text-5xl leading-none text-libera-500 font-bold text-center px-8 mb-12 text-quan"
                    data-sal="slide-up"
                    data-sal-duration="1200"
                    data-sal-easing="ease">
                    Únete a Nuestro Equipo <br />de Asesores Inmobiliarios
                    </h1>
                {/* <div
                        data-sal="slide-up"
                        data-sal-duration="1500"
                        data-sal-easing="ease"
                        className="mt-8 md:mx-24 md:px-12 xl:px-48 xl:mx-48 sm:px-12 md:text-xl text-lg text-justify ref1">

                    </div> */}
                {/* <div className="container justify-center"> */}
                <div className="grid justify-center items-center mb-8">
                    <ButtonIcon text="Solicita Información" icon="tel" to="tel:9998286805" />
                </div>
                {/* </div> */}
                <Img fluid={fondo.sharp.fluid} alt="fondo" />
            </div>
        </div>
        // </BackgroundImg>
    )
}

export default UneteAlEquipo

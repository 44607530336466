import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SliderElement from './SliderElement'
import logoArenales from '../images/logoArenales.png'
import logoCarbonerasReserva from '../images/carbonerasRlogo.png'
import logoTerus from '../images/santaMar.png'
import logoTotem from '../images/totem2.png'


const Slider = () => {

  const fondo = useStaticQuery(graphql`
    {
      sliderTerus: file(relativePath: { eq: "sliderSantaMar.png" }) {
        sharp: childImageSharp { fluid(maxWidth: 1600) { ...GatsbyImageSharpFluid } } }
      sliderArenales: file(relativePath: { eq: "sliderArenales.jpg" }) { 
        sharp: childImageSharp { fluid { ...GatsbyImageSharpFluid } } }
      sliderCarboneras: file(relativePath: { eq: "sliderCarbonerasA.jpg" }) {
        sharp: childImageSharp { fluid { ...GatsbyImageSharpFluid } } }
      sliderOcelot: file(relativePath: { eq: "sliderOcelot.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920) { ...GatsbyImageSharpFluid } } }
      
      sliderXomak: file(relativePath: { eq: "sliderXomak.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920) { ...GatsbyImageSharpFluid } } }
      
      sliderAndira: file(relativePath: { eq: "sliderAndira.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920) { ...GatsbyImageSharpFluid } } }
      sliderStoras: file(relativePath: { eq: "sliderStoras.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920) { ...GatsbyImageSharpFluid } } }
      
    }
  `)
  const desarrollos = [
    {
      nombre: "Arenales",
      fondo: fondo.sliderArenales.sharp.fluid,
      logo: logoArenales,
      link: "/desarrolloArenales",
      color:"rgba(230, 47, 114, var(--bg-opacity))"
    },
    {
      nombre: "Carboneras",
      fondo: fondo.sliderCarboneras.sharp.fluid,
      logo: logoCarbonerasReserva,
      link: "/desarrolloCarbonerasReserva",
      color:"rgba(20,118,59,var(--bg-opacity))"
    },
    {
      nombre: 'Totem',
      fondo: fondo.sliderOcelot.sharp.fluid,
      logo: logoTotem,
      link: "https://totem.grupolibera.mx/",
      color:"rgba(106, 182, 153, var(--bg-opacity))"
    },
    {
      nombre: "SantaMar",
      fondo: fondo.sliderTerus.sharp.fluid,
      logo: logoTerus,
      link: "https://santamarsisal.mx/",
      color:"rgba(89, 194, 187, var(--bg-opacity))"
    },
    


  ]
  // const resizeSurrounding = () => {
  //     const width = document.querySelector('#width').value;
  //     document.querySelector('#surrounding').style.width = `${width}px`;
  // };
  return (
    <div className="bg-white flex">

      {desarrollos.map(desarrollo =>
        <SliderElement
          key={desarrollo.nombre}
          fondo={desarrollo.fondo}
          logo={desarrollo.logo}
          nombre={desarrollo.nombre}
          enlace={desarrollo.link}
          color={desarrollo.color} />

      )}

    </div>
  )
}

export default Slider

import React from 'react'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import ButtonIcon from './ButtonIcon'

function InvierteConNosotros() {
    const { fondo24 } = useStaticQuery(graphql`
    {
      fondo24: file(relativePath: { eq: "fondo24.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth: 800) { ...GatsbyImageSharpFluid } } }
    }`)

    return (
        <div
            className="py-8 grid grid-cols-1 lg:grid-cols-2 items-center py-12 lg:py-24 text-gray-700 container m-auto">
            <div className="container m-auto grid justify-center">
                <div className="px-6 sm:px-32 md:px-24 lg:px-4 xl:px-24 my-12 m-auto container max-w-3xl">
                    <h1 className="text-3xl sm:text-4xl md:text-4xl text-libera-500 mb-8 text-quan leading-none
                    font-bold text-center leading-10 md:leading-none lg:text-left md:px-8 lg:px-0"
                        data-sal="slide-up"
                        data-sal-easing="ease"
                        data-sal-duration="1200"
                    >
                        Invierte Con Nosotros
                    </h1>
                    <div className=" md:text-xl md:px-12 lg:pl-0 lg:pr-8 px-4 sm:px-0 mb-8
                    text-base text-center md:text-justify"
                        data-sal="slide-up"
                        data-sal-easing="ease"
                        data-sal-duration="1300">
                        Asegura tu futuro invirtiendo en terrenos en las mejores zonas de
                        Yucatán.
                    </div>
                    <ButtonIcon text="Invierte Ahora" icon="tel" to="tel:9993462300" />

                </div>
            </div>
            <div className="text-center"
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-duration="1400">
                <Img
                    fluid={fondo24.sharp.fluid}
                    className="m-auto px-8 mb-12 sm:mb-8 md:mb-0"
                />
            </div>

        </div>
    )
}

export default InvierteConNosotros

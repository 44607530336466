import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import { Link } from "@reach/router";

const MyCarousel = () => {
  const [conte, setConte] = React.useState([]);

  React.useEffect(() => {
    obtenerDatos();
  }, []);

  const obtenerDatos = async () => {
    const data = await fetch('https://grupolibera.mx/admin/clases/jsondatos.php', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const contenido = await data.json();
    setConte(contenido);
  };

  return (
    <Carousel nextLabel={' '} prevLabel={' '}>
      {conte.map(item => (
        <Carousel.Item key={item.id} style={{ cursor: 'pointer' }}>
          <a onClick={() => { window.location.href = `/noticia/${item.tituloUrl}` }}>
            <img src={item.ruta} />
            <Carousel.Caption>
              <h1 className="text-4xl leading-none font-semibold lg:text-4xl text-quan lg:pt-10">
                {item.titulo}
              </h1>
              <p className="lg:pt-2 text-md lg:text-xl font-semibold text-white text-montserrat font-thin">
                {item.subtitulo}
              </p>
            </Carousel.Caption>
          </a>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default MyCarousel;
